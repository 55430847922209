import React from "react"
import Logo from "../assets/img/logo.svg"
import MyLink from "../components/link"
import Services from "../includes/services"

export default function Footer() {
    return <footer id="footer">
        <div className="container flex flex-row flex-wrap">
            <div className="lg:w-1/4 md:w-1/2 w-full">
                <MyLink to="/" label="Artificial Studio">
                    <Logo alt="Artificial Studio" className="logo logo-main" />
                </MyLink>
                <p className="cl-footer mt-7">&copy; {new Date().getFullYear()}. <strong>Artificial Studio</strong>.</p>
                <nav>
                    <ul>
                        <li><MyLink to="/legal/terms-conditions/">Terms &amp; Conditions</MyLink></li>
                        <li><MyLink to="/legal/privacy-policy/">Privacy Policy</MyLink></li>
                    </ul>
                </nav>
            </div>
            <div className="lg:w-1/4 md:w-1/2 w-full mt-8 lg:mt-0">
                <h3 className="h5">Get in Touch</h3>
                <ul>
                    <li><a href="tel:+27861377704">+27 (0) 86 137 7704</a></li>
                    <li><a href="mailto:info@artificial.studio">info@artificial.studio</a></li>
                </ul>
            </div>
            <div className="lg:w-1/2 w-full mt-8 lg:mt-0">
                <h3 className="h5">Services</h3>
                <nav>
                    <ul className="columns columns-2">
                        <Services />
                    </ul>
                </nav>
            </div>
        </div>
    </footer>
}